import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  "Privacy Policy": "en/privacy-policy"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/privacy-policy"
  },
  {
    hreflang: "de",
    href: "/de/datenschutz"
  },
  {
    hreflang: "x-default",
    href: "/en/privacy-policy"
  }
];

const PrivacyPolicy = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Privacy Policy"
        description="Details about my data protection policy to aim to explain how my services work and how the protection of your personal data is guaranteed."
        lang="en"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Data protection</H>
        <p>Data protection policy</p>
        <p>
          Below I would like to explain what data I collect, process and use when and for what purpose. The aim is to
          explain to you how my services on offer work, and how the protection of your personal data is guaranteed in
          this respect.
        </p>
        <p>I only collect, process and use personal data where you have consented to it or legislation allows.</p>
        <p>
          This data protection policy can be downloaded, saved and printed at any time via the URL
          https://matthiaskupperschmidt.com/privacy-policy/.
        </p>
        <H as="h2">Controller’s name and address</H>
        <p>The Controller in the sense of data protection legislation is:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Copenhagen, Denmark</p>
        <p>You can contact me at any time by email at legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Name and address of the Data Protection Officer</H>
        <p>Data Protection Officer’s contact details:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Copenhagen, Denmark</p>
        <p>You can contact me at any time by email at legal@matthiaskupperschmidt.com.</p>
        <H as="h2">General points about data protection</H>
        <p>Scope of personal data processing</p>
        <p>
          I only process users’ personal data where required for providing an operational website as well as content
          and services. As a rule, users’ personal data is only processed after the user has consented. An exception
          applies in such cases where consent cannot be obtained beforehand for factual reasons, and processing of the
          data is permitted by statutory regulations.
        </p>
        <H as="h2">Legal basis for processing personal data</H>
        <p>
          Article 6 (1, a) of the EU General Data Protection Directive (DGPR) serves as the legal basis where I obtain
          a data subject’s consent for processing personal data.
        </p>
        <p>
          Article 6 (1, b) GDPR serves as the legal basis where the processing of personal data is required for
          performing a contract where the data subject is a party to the contract. This also applies to processing
          required for implementing pre-contractual measures.
        </p>
        <p>
          Article 6 (1, c) GDPR serves as the legal basis where the processing of personal data is required for
          performing a legal obligation that my company is subject to.
        </p>
        <p>
          Where the processing is required for safeguarding a legitimate interest of my company or a third party, and
          the data subject’s interests and fundamental rights and freedoms do not outweigh the first-mentioned
          interest, Article 6 (1, f) GDPR serves as the legal basis for processing.
        </p>

        <H as="h2">Data erasure and storage duration</H>
        <p>
          The data subject’s personal data is erased or blocked as soon as the purpose of storage ceases to exist.
          Storage can also occur if intended by European or domestic legislators in Regulations, Laws or other
          requirements under EU Law to which the Controller is subject. Data is also deleted or erased if a storage
          period prescribed by the stated legislation has expired, unless there is a requirement for continued storage
          of the data for entering into or performing a contract.
        </p>
        <p>Types of processed data:</p>
        <p>– Contact details (e.g. email, name).</p>
        <p>– Usage data (e.g., pages visited, interest in content, access times).</p>
        <p>– Meta/communications data (e.g. device information, IP addresses).</p>
        <H as="h2">Categories of data subjects</H>
        <p>Visitors to and users of the online offer, all summarised as “users” the hereafter.</p>
        <H as="h2">Purpose of processing</H>
        <p>– Provision of the online offer, its functions and content.</p>
        <p>– Responding to contact enquiries and communication with users.</p>
        <p>– Security measures.</p>
        <p>– Audience gauging/marketing</p>
        <H as="h3">Definitions used</H>
        <p>
          ‘Personal data’ means any information relating to an identified or identifiable natural person (‘data
          subject’); an identifiable natural person is one who can be identified, directly or indirectly, in
          particular by reference to an identifier such as a name, an identification number, location data, an online
          identifier (e.g. cookie) or to one or more factors specific to the physical, physiological, genetic, mental,
          economic, cultural or social identity of that natural person.
        </p>
        <p>
          ‘Processing’ means any operation or set of operations which is performed on personal data or on sets of
          personal data, whether or not by automated means. The term is far reaching and covers practically any
          dealing with data.
        </p>
        <p>
          ‘Controller’ refers to the natural or legal person, public authority, agency or other body which, alone or
          jointly with others, determines the purposes and means of the processing of personal data.
        </p>
        <H as="h2">Relevant legal bases</H>
        <p>
          In accordance with Article 13 DGPR, I am informing you of the legal bases of our data processing. The
          following applies where the legal basis is not stated in the data protection policy: The legal basis for
          obtaining consent is Article 6 (1, a) and Article 7 GDPR, the legal basis for processing for performing our
          services and implementing contractual measures, as well as responding to enquiries is Article 6 (1, b) GDPR,
          the legal basis for processing for complying with our legal obligations is Article 6 (1, c) GDPR, and the
          legal basis for processing for safeguarding our legitimate interests is Article 6 (1, f) GDPR.
        </p>
        <H as="h2">Collaboration with processors and third parties</H>
        <p>
          Where, when processing, I disclose data to other parties and companies (processors or third parties), or
          grant direct or other access to the data, this only occurs based on statutory permission (e.g. if
          transferring data to third parties, like a payment service provider, is required for performing a contract
          in accordance with Article 6 (1, b) GDPR, you have provided consent, a legal obligation is in place, or
          based on our legitimate interests (e.g. when using agents, web hosters etc.).
        </p>
        <p>
          Where I commission third parties with the processing of data based on a so-called ‘processing agreement’,
          this occurs based on Article 28 GDPR.
        </p>
        <H as="h2">Transmission to third countries</H>
        <p>
          Where I process data in a third country (i.e. outside the European Union (EU) or the European Economic Area
          (EEA)), or this happens when used as part of third-party services or the disclosure or, as may apply,
          transfer of data to third parties, this only occurs if required to perform our (pre-)contractual duties,
          based on your consent, due to a legal obligation, or based on our legitimate interests. Subject to statutory
          or contractual permission, I only process, or arrange for data to be processed, in a third country where the
          specific requirements of Articles 44 et seq GDPR are in place. In other words, processing occurs, for
          example, based on particular guarantees such as the officially recognised determination of the data
          protection level corresponding to the EU (e.g. for the USA by the ‘Privacy Shield’) or compliance with
          officially recognised specific obligations (so-called ‘standard contract clauses’).
        </p>
        <H as="h2">Rights of data subjects</H>
        <p>
          You have the right to demand confirmation as to whether the data in question is being processed and for
          information about this data, as well as further information and a copy of the data in accordance with
          Article 15 GDPR.
        </p>
        <p>
          In accordance with Article 16 GDPR, you have the right to demand that data concerning you is completed, or
          incorrect data concerning you rectified.
        </p>
        <p>
          In accordance with Article 17 GDPR, you have the right to demand the erasure of data concerning you without
          undue delay or, alternatively in accordance with Article 18 GDPR, restricted processing of the data.
        </p>
        <p>
          You have the right to demand receipt of data that you have provided me with in accordance with Article 20
          GDPR, and to request its transmission to another Controller.
        </p>
        <p>
          You also have the right in accordance with Article 77 GDPR to lodge a complaint with the relevant
          supervisory body.
        </p>
        <H as="h2">Revocation</H>
        <p>You have the right to revoke consent granted in accordance with Article 7 (3) GDPR with future effect.</p>
        <H as="h2">Right to object</H>
        <p>
          In accordance with Article 21 GDPR, you can object to future processing of data concerning you. In
          particular, the objection can be against processing for the purposes of direct advertising.
        </p>
        <H as="h2">Cookies and the right to object with direct advertising</H>
        <p>
          Cookies refer to small files stored on users’ computers Different details can be stored within the cookies.
          A cookie serves primarily to store details about a user (or, as may apply the device on which the cookie is
          stored) during or even after his/her visit within an online offer. Cookies that are deleted after a user
          leaves an online offer and closes their browser are referred to as temporary cookies or, as may apply,
          session cookies. By way of example, a login status can be stored in such a cookie. Cookies that remain even
          after the browser has been closed are referred to as permanent or persistent. By way of example, this allows
          the login status to be stored if the user visits it after several days. The users’ interests for gauging the
          audience or marketing purposes can also be stored in such a cookie. Cookies offered by providers other than
          the controller operating the online offer are referred to as third-party cookies (otherwise first-party
          cookies if only its cookies exist).
        </p>
        <p>I can use temporary and permanent cookies, and clarify this under our data protection policy.</p>
        <p>
          Where users do not want cookies to be stored on their computer, they are requested to disable the
          corresponding option in the system settings. Stored cookies can be deleted in the browser’s system settings.
          Excluding cookies can lead to restricted functioning of this online offer.
        </p>
        <p>
          A general objection to the use of cookies placed for the purposes of online marketing can be declared with a
          wide range of services, above all in the event of tracking, via the US site
          http://www.aboutads.info/choices/ or the EU site http://www.youronlinechoices.com/. In addition, the storage
          of cookies can be achieved by means disabling them in the browser’s settings. Please note that not all
          functions of this online offer may then be used.
        </p>
        <p>
          When accessing our website, users are informed about the use of cookies for analysis purposes by an info
          banner referring to the data protection policy. In this respect, there is also a reference to how the
          storage of cookies can be prevented in the browser settings. &nbsp;This so-called cookie tracking filter
          assists the user in saving which cookies are to be set. His/her own cookie is stored for this purpose. If
          you delete your cookies, you should not delete this cookie, as otherwise the cookie tracking filter is
          unable to recognise your preferences.
        </p>
        <H as="h2">Erasing data</H>
        <p>
          Data processed by me is erased in accordance with Articles 17 and 18 GDPR, or restricted in its processing.
          Unless expressly stated as part of the data protection policy, data stored with me is deleted as soon as it
          is not required for its purpose, and no statutory duties of retention stand in the way of deletion. Where
          data is not deleted because it is required for other statutory purposes permitted by legislation, its
          processing is restricted. In other words, the data is blocked and not processed for other purposes. By way
          of example, this applies to data needing to be retained under commercial or tax law.
        </p>
        <H as="h2">Business-related processing</H>
        <p>I also process</p>
        <p>– contract data (e.g. contractual object, term, client category).</p>
        <p>– payment data (e.g. bank details, payment history)</p>
        <p>
          of my customers, potential clients, and business partners for the purpose of providing contractual services,
          servicing and customer care, marketing, advertising, and market research.
        </p>
        <H as="h2">Hosting</H>
        <p>
          The hosting services used by me serve to provide the following services: Infrastructure and platform
          services, computing capacity, memory space and database services, and security services as well as technical
          maintenance services that I use for the purpose of operating this online offer.
        </p>
        <p>
          In doing so, me or, as my apply, my hosting provider process inventory data, contact details, content data,
          contract data, usage data, meta and communications data of customers and potential clients of and visitors
          to this online offer based on our legitimate interests in efficiently and securely providing this online
          offer in accordance with Article 6 (1, f) GDPR in conjunction with Article 28 GDPR (entering into a
          processing agreement).
        </p>
        <H as="h2">Collection of access data and log files</H>
        <p>
          Based on my legitimate interest in the sense of Article 6 (1, f) GDPR, I or, as may apply, my hosting
          provider collects data about any access to the server on which this service is located (so-called server log
          files). Access data includes the name of the website accessed, file, date and time of access, amount of data
          transferred, report of successful access, browser type along with version, user’s operating system, referrer
          URL (site previously visited), IP address and requesting provider.
        </p>
        <p>
          Temporary storage of the IP address by the system is necessary to enable delivery of the website to the
          user’s computer. The user’s IP address needs to remain stored for the duration of the session to allow this.
        </p>
        <p>
          Storage takes place in log files to ensure the functionality of the website, above all for analysing an
          attack (DOS attack) and the misuse of tools provided. &nbsp;The data also serves to optimise the website and
          ensure the security of my IT systems. Data is not evaluated for marketing purposes in this respect.
        </p>
        <p>
          The data is erased as soon as it is no longer required for achieving the purpose of its collection.
          &nbsp;This is generally the case after 30 days.
        </p>
        <p>
          The collection of data for providing the website and storing the data in log files is mandatory for
          operating the website. As a result, the user is not able to object.
        </p>
        <H as="h2">Providing contractual services</H>
        <p>
          I process inventory data (e.g. names and addresses as well as contact details of customers) and contract
          data (e.g. services used, names of points of contact, payment information) for the purpose of performing my
          contractual obligations and services in accordance with Article 6 (1, b) GDPR. Entries marked as mandatory
          in online forms are required for entering into the contract.
        </p>
        <p>
          When making use of my online services, I store the IP address and time of the respective user activity.
          Storage occurs based on my legitimate interests, as also to protect the user from misuse and other
          unauthorised usage. This data is in principle not disclosed to third parties, unless required for following
          up my claims or there is a statutory obligation in this respect in accordance with Article 6 (1, c) GDPR.
        </p>
        <p>
          I process usage data (e.g. pages visited of my online offer, interest in my products) and content data (e.g.
          entries in the contact form or user profile) for promotional purposes in a user profile to show users, by
          way of example, product information based on services previously made use of by it.
        </p>
        <p>
          The data is deleted on expiry of statutory warranty and comparable duties, and the requirement to retain the
          data is checked every three years. In the event of statutory archiving duties, erasure is on its expiry.
          Details in any customer account remain until its deletion.
        </p>
        <H as="h2">Administration, accounting, office organisation, contact administration</H>
        <p>
          I process data as part of administrative tasks as well as the organisation of my operation, accounting, and
          compliance with statutory duties such as archiving. As such, I process the same data that I process when
          providing my contractual services. The bases for processing are Article 6 (1, c) GDPR and Article 6 (1, f)
          GDPR. Customers, prospective clients, business partners and visitors to the website are affected by
          processing. The purpose and interest in processing lies in the administration, accounting, office
          organisation, and archiving of data, i.e. tasks serving to maintain my business activities, perform my
          tasks, and provide my services. The erasure of data with regard to contractual services and communication
          corresponds to the details stated with these processing activities.
        </p>
        <p>
          As such, I disclose or transmit data to the Tax Office, consultants such as tax advisers or auditors, and
          other charge collectors and payment service providers.
        </p>
        <p>
          In addition, based on my commercial interests I store details about suppliers, organisers, and other
          business partners, for example for the purpose of subsequently making contact. In principle, I share this
          data relating on the whole to companies on a permanent basis.
        </p>
        <H as="h2">Business analysis and market research</H>
        <p>
          In order to be able to operate my business commercially and identify market trends, and customer and user
          wishes, I analyse the data available to me about business processes, contracts, enquiries etc. In doing so,
          I process inventory data, communications data, contract data, payment data, usage data, and meta data based
          on Article 6 (1, f) GDPR, where data subjects include customers, potential clients, business partners, and
          visitors to and users of the online offer.
        </p>
        <p>
          Analysis takes place for the purpose of business evaluation, marketing, and market research. This allows me
          to consider the profiles of registered users with details such as their purchasing processes. Analysis
          serves me to increase user friendliness, and to optimise my offer and business efficiency. Analysis serves
          me alone and is not disclosed externally, unless it involves anonymised analysis with summarised values.
        </p>
        <p>
          Where this analysis or profiles is/are personal, it/they is/are erased or anonymised on termination of the
          user, otherwise after two years as of terminating the contract. In addition, overall commercial analysis and
          general determination of trends is produced where possible.
        </p>
        <H as="h2">Email signup function</H>
        <p>
          Users have the option to subscribe to my content with their email. Users are provided with the required
          mandatory details when registering. Details input when registering are used for taking advantage of the
          offer. Users can receive emails relating to offers or changes to the scope of the offer or technical
          conditions. If users have cancelled their user account, their data with regard to the user account is
          erased, subject to retention being required for reasons under commercial or tax law in accordance with
          Article 6 (1, c) GDPR. It is a matter for users to safeguard their data before the end of the contract in
          the event of cancellation. I am entitled to irrevocably erase all of the user’s data stored during the
          contract.
        </p>
        <p>
          When my registration and login functions are made use of, as well as when the user account is used, I store
          the IP address and the time of the respective user activity. Storage takes place not only based on my
          legitimate interests, but also the user to protect against abuse and other unauthorised usage. This data is
          in principle not disclosed to third parties unless required to follow up my claims or there is a statutory
          obligation in this respect in accordance with Article 6 (1, c) GDPR.
        </p>
        <H as="h2">Contact</H>
        <p>
          When contacting me (for example by means of the contact form, email, telephone, or via social media), the
          user’s details are processed for handling the contact enquiry and in accordance with Article 6 (1, b) GDPR.
          The user’s details can be stored in a customer relationship management system (CRM system) or comparable
          enquiry management system.
        </p>
        <p>
          I delete the enquiries as soon as they are no longer required. I review the requirement every two years, and
          statutory archiving duties also apply.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default PrivacyPolicy;
